html,
body,
#root,
#app {
    height: 100%;
    padding: 0;
    margin: 0;
}

#app {
    background-color: aquamarine;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .files {
            width: 100%;
            margin: 2rem;

            .file {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                width: 100%;
                margin-bottom: 1rem;
                box-shadow: 0.25rem 0.25rem 0.5rem black;

                .file-value {
                    display: inline-block;

                    .pages {
                        width: 2rem;
                    }
                }
            }
        }
    }
}